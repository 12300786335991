// @flow
import * as React from 'react';

import BreadcrumbItem from 'components/ui/Breadcrumb/BreadcrumbItem';
import BreadcrumbItemWrapper from 'components/ui/Breadcrumb/BreadcrumbItemWrapper';
import Icon from 'components/ui/Icon';
import RecursiveDimensionCategoryBreadcrumb from 'components/DataCatalogApp/DimensionDetailsPage/DimensionLocationSection/DimensionCategoryRow/RecursiveDimensionCategoryBreadcrumb';
import RecursiveFieldCategoryBreadcrumb from 'components/DataCatalogApp/common/CategoryPath/RecursiveFieldCategoryBreadcrumb';
import { FIELD_TYPE } from 'models/DataCatalogApp/types';
import type { ElementType } from 'models/DataCatalogApp/types';

type Props = {
  className?: string,
  elementType: ElementType,

  id: string | void,
  // The maximum number of category breadcrumbs to display.
  maxDepth?: number | void,
};

const HOME_BREADCRUMB = (
  <BreadcrumbItemWrapper className="category-path__breadcrumb-item">
    <BreadcrumbItem value="home">
      <Icon type="home" />
    </BreadcrumbItem>
  </BreadcrumbItemWrapper>
);

// Display the category name and all parent categories in a breadcrumb.
// NOTE(stephen): This component does not actually render a `Breadcrumb` ui
// component because that component has a strict limitation on the type of
// children that can be passed. Instead, the same CSS is applied through the
// class name and the individual items are rendered instead.
function CategoryPath({
  id,
  className = '',
  elementType,
  maxDepth = undefined,
}: Props) {
  return (
    <div className={`category-path ${className}`}>
      {HOME_BREADCRUMB}
      {id !== undefined &&
        (elementType === FIELD_TYPE ? (
          <RecursiveFieldCategoryBreadcrumb id={id} maxDepth={maxDepth} />
        ) : (
          <RecursiveDimensionCategoryBreadcrumb id={id} maxDepth={maxDepth} />
        ))}
    </div>
  );
}

export default (React.memo<Props>(
  CategoryPath,
): React.AbstractComponent<Props>);
