// @flow
import * as React from 'react';
import { useLazyLoadQuery } from 'react-relay/hooks';

import BreadcrumbItem from 'components/ui/Breadcrumb/BreadcrumbItem';
import BreadcrumbItemWrapper from 'components/ui/Breadcrumb/BreadcrumbItemWrapper';
import { RELAY_DIMENSION_CATEGORY_ROOT_ID } from 'components/DataCatalogApp/constants';
import type { RecursiveDimensionCategoryBreadcrumbQuery } from './__generated__/RecursiveDimensionCategoryBreadcrumbQuery.graphql';

type Props = {
  id: string,
  maxDepth?: number,
};

const POLICY = { fetchPolicy: 'store-or-network' };

const PLACEHOLDER_BREADCRUMB = (
  <BreadcrumbItemWrapper className="category-path__breadcrumb-item">
    <BreadcrumbItem value="placeholder">...</BreadcrumbItem>
  </BreadcrumbItemWrapper>
);

// $DataCatalogQueryMismatch
// This is in Hasura still even though the rest is fetched with REST.
// This was pretty trivial to copy paste and just change to dimension category.
// Consider rewriting to fetch with a REST endpoint or just pass all parents
// back in the endpoint?
function RecursiveDimensionCategoryBreadcrumb({ id, maxDepth = 50 }: Props) {
  const data = useLazyLoadQuery<RecursiveDimensionCategoryBreadcrumbQuery>(
    graphql`
      query RecursiveDimensionCategoryBreadcrumbQuery($id: ID!) {
        node(id: $id) {
          ... on dimension_category {
            name
            parent {
              id
            }
          }
        }
      }
    `,
    { id },
    POLICY,
  );
  if (!data.node) {
    return null;
  }

  // Update the max depth. Each time this component renders recursively, the
  // max depth will be decremented.
  const newMaxDepth = maxDepth - 1;
  const { name = '', parent = undefined } = data.node;

  // Test to see if we have reached the maximum number of path segments to
  // render. If we have not, then we can continue rendering the next parent
  // category if it exists.
  const parentId = parent ? parent.id : undefined;

  // When we have reached the root, we want to render the Home breadcrumb item
  // to signify there are no parents left.
  const reachedRoot =
    parentId === RELAY_DIMENSION_CATEGORY_ROOT_ID || parentId === undefined;

  // If we have hit the maximum depth and still have a parent for this category,
  // we render a placeholder breadcrumb to signify that there are more parents
  // remaining that cannot be displayed.
  const hitMaxDepthBeforeRoot = newMaxDepth <= 0 && !reachedRoot;
  return (
    <React.Suspense fallback={PLACEHOLDER_BREADCRUMB}>
      {hitMaxDepthBeforeRoot && PLACEHOLDER_BREADCRUMB}
      {newMaxDepth > 0 && !reachedRoot && parentId !== undefined && (
        <RecursiveDimensionCategoryBreadcrumb
          id={parentId}
          maxDepth={newMaxDepth}
        />
      )}
      <BreadcrumbItemWrapper className="category-path__breadcrumb-item">
        <BreadcrumbItem value={name}>{name}</BreadcrumbItem>
      </BreadcrumbItemWrapper>
    </React.Suspense>
  );
}

export default (React.memo<Props>(
  RecursiveDimensionCategoryBreadcrumb,
): React.AbstractComponent<Props>);
