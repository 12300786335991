// @flow
import { DIMENSION_TYPE, FIELD_TYPE } from 'models/DataCatalogApp/types';
import { databaseIdToRelayId } from 'util/graphql/hasura';
import { localizeUrl } from 'util/util';
import { slugify } from 'util/stringUtil';
import type { ElementType } from 'models/DataCatalogApp/types';

// Build a URL that will send the user to the field details page for the given
// field ID.
// NOTE(stephen): If we end up with many of these utilities, consider
// centralizing them.
export default function buildElementDetailsPageLink(
  id: string,
  name: string,
  elementType: ElementType,
): string {
  const slug = slugify(name);
  const suffix = slug.length > 0 ? `${slug}--${id}` : id;
  const elementTypeBackend =
    elementType === DIMENSION_TYPE ? 'dimension' : 'indicator';
  return localizeUrl(`/data-catalog/${elementTypeBackend}/${suffix}`);
}

// This method exists for the semi-rare instances where we have the *database*
// field ID instead of the relay field ID. We need to convert the db ID into
// the relay globally unique ID before we can build the link.
// TODO(stephen): Monitor this usage.
export function buildFieldDetailsPageLinkFromDatabaseId(
  dbId: string,
  name: string,
): string {
  const relayId = databaseIdToRelayId(dbId, 'field');
  return buildElementDetailsPageLink(relayId, name, FIELD_TYPE);
}
