// @flow
import Promise from 'bluebird';
import invariant from 'invariant';

import APIService, { API_VERSION } from 'services/APIService';
import CachedMapService from 'services/wip/CachedMapService';
import CategoryService from 'services/wip/CategoryService';
// no way to avoid this circular dependency unfortunately
// eslint-disable-next-line import/no-cycle
import Dimension from 'models/core/wip/Dimension';
import autobind from 'decorators/autobind';
import { convertIDToURI, convertURIToID } from 'services/wip/util';
import type { APIVersion, HTTPService } from 'services/APIService';
import type { Cache, RejectFn, ResolveFn } from 'services/wip/CachedMapService';
import type { URI, URIConverter } from 'services/types/api';

class DimensionService extends CachedMapService<Dimension>
  implements URIConverter {
  apiVersion: APIVersion = API_VERSION.V2;
  endpoint: string;
  _httpService: HTTPService;

  constructor(httpService: HTTPService, includeHiddenDimensions: boolean) {
    super();
    this.endpoint = includeHiddenDimensions
      ? 'query/dimensions?include_hidden=true'
      : 'query/dimensions';
    this._httpService = httpService;
  }

  buildCache(
    resolve: ResolveFn<Dimension>,
    reject: RejectFn,
  ): Promise<Cache<Dimension>> {
    return Promise.all([
      this._httpService.get(this.apiVersion, this.endpoint),
      CategoryService.getAll(),
    ])
      .then(([rawDimensionList]) => {
        const dimensions = [];
        rawDimensionList.forEach(rawDimension => {
          const {
            description,
            dimensionCode,
            id,
            nameTranslations,
          } = rawDimension;
          rawDimension.categoryMapping.forEach(mapping => {
            const category = CategoryService.UNSAFE_get(mapping.categoryId);
            const dimension = Dimension.fromObject({
              category,
              description,
              dimensionCode,
              id,
              nameTranslations,
            });
            dimensions.push({
              dimension,
              categoryOrder: category?.ordering(),
              mappingOrder: mapping.ordering,
            });
          });
        });
        const dimensionMappingCache = {};
        dimensions
          .sort((a, b) => {
            if (
              a.categoryOrder === b.categoryOrder ||
              a.categoryOrder === undefined ||
              b.categoryOrder === undefined
            ) {
              return a.mappingOrder - b.mappingOrder;
            }
            return a.categoryOrder - b.categoryOrder;
          })
          .forEach(({ dimension }) => {
            const category = dimension.category();
            // NOTE(Kenneth): Since dimensions are not going to appear in only one
            // category,it makes sense to use both dimension_id and category_id
            // as the key in the dimensionMappingCache.
            dimensionMappingCache[
              `${dimension.dimensionCode()}_${category ? category.id() : ''}`
            ] = dimension;
          });
        resolve(dimensionMappingCache);
      })
      .catch(reject);
  }

  convertURIToID(uri: URI): string {
    return convertURIToID(uri, this.apiVersion, this.endpoint);
  }

  convertIDToURI(id: string): URI {
    return convertIDToURI(id, this.apiVersion, this.endpoint);
  }

  // NOTE (Kenneth): This service was built with the assumption that each dimension will only
  // belong to one category. That assumption is no longer valid. Because of that, the cache key
  // is a combination of dimensionId and categoryId in the format `<dimensionId>_<categoryId>`
  findByFirstDimension(
    cache: Cache<Dimension>,
    dimensionId: string,
  ): Dimension | void {
    const firstKey = Object.keys(cache).find(key =>
      key.startsWith(dimensionId),
    );
    return firstKey ? cache[firstKey] : undefined;
  }

  /**
   * Retrieve a single value from the cache.
   *
   * key: string
   *
   * @returns Promise<T | void>
   */
  @autobind
  get(dimension: string): Promise<Dimension | void> {
    if (this._mappingCache) {
      return Promise.resolve(
        this.findByFirstDimension(this._mappingCache, dimension),
      );
    }

    return this.fetchMapping().then(() => {
      invariant(
        this._mappingCache !== undefined,
        'Mapping cache cannot be undefined if the promise was successful.',
      );
      return this.findByFirstDimension(this._mappingCache, dimension);
    });
  }

  /**
   * Retrieve a single value from the cache triggering closest <React.Suspense>
   * if cache is not yet ready.
   *
   * key: string
   *
   * @returns Dimension | void
   */
  @autobind
  suspensedGet(dimension: string): Dimension | void {
    if (!this._mappingCache) {
      throw this.fetchMapping();
    }
    return this.findByFirstDimension(this._mappingCache, dimension);
  }
}

export const FullDimensionService: DimensionService = new DimensionService(
  APIService,
  true,
);

export default (new DimensionService(APIService, false): DimensionService);
